import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Image from '../components/image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CategoryTemplate = ({ pageContext: { categoryId, locale = 'en' } }) => {
  const data = useStaticQuery(graphql`
    query {
      categories: allCategoriesJson(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            uuid
            en {
              name
            }
            ptBR {
              name
            }
          }
        }
      }
    }
  `);

  const category = data.categories.edges.find(
    ({ node }) => node.id === categoryId
  );

  const gLocale = locale.replace('-', '');

  const baseUrl = locale === 'pt-BR' ? '/pt-BR/trabalhos/' : '/work/';
  const baseTitle = locale === 'pt-BR' ? 'Trabalhos' : 'Work';

  return (
    <Layout>
      <SEO
        lang={locale}
        title={category.node[gLocale].name}
        description={
          locale === 'pt-BR'
            ? `Fotos do trabalho de Katharina Welper na categoria ${category.node[gLocale].name}`
            : `Photos of Katharina Welper's ${category.node[gLocale].name} work`
        }
      />
      <div className="category-details">
        <div className="breadcrumb">
          <a href={baseUrl}>{baseTitle}</a> <span>&rsaquo;</span>{' '}
          {category.node[gLocale].name}
        </div>
        <Image category={category.node.uuid} />
      </div>
    </Layout>
  );
};

CategoryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    categoryId: PropTypes.string.isRequired,
    locale: PropTypes.string
  })
};

export default CategoryTemplate;
