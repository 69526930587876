import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import StackGrid from 'react-stack-grid';
import FsLightbox from 'fslightbox-react';

import useCategories from '../hooks/useCategories';

const Image = ({ category }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  const data = useCategories();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: undefined
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }

  let customSources;
  if (category === 'videos') {
    customSources = [
      <iframe
        src="https://player.vimeo.com/video/407522195?autoplay=1"
        width="720"
        height="405"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        title="Ele está no meio de nós"
      />
    ];
  } else if (category === 'bordados') {
    customSources = [
      <iframe
        src="https://player.vimeo.com/video/257139119?autoplay=1"
        width="640"
        height="352"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        title="doméstico perverso"
      />
    ];
  }

  return (
    <div className="mosaic">
      {isMobile ? (
        <div>
          {data[category].edges.map(({ node }, index) => (
            <a
              className="mosaic__item"
              href={`/work#item-${index}`}
              key={node.childImageSharp.thumb.src}
              onClick={e => {
                e.preventDefault();
                openLightboxOnSlide(index + 1);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  e.preventDefault();
                  openLightboxOnSlide(index + 1);
                }
              }}
            >
              <Img fluid={node.childImageSharp.thumb} />
            </a>
          ))}
        </div>
      ) : (
        <StackGrid
          columnWidth={
            typeof window !== 'undefined' && window.innerWidth < 768
              ? '100%'
              : '20%'
          }
          key={category}
          vendorPrefix
        >
          {data[category].edges.map(({ node }, index) => (
            <a
              className="mosaic__item"
              href={`/work#item-${index}`}
              key={node.childImageSharp.thumb.src}
              onClick={e => {
                e.preventDefault();
                openLightboxOnSlide(index + 1);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  e.preventDefault();
                  openLightboxOnSlide(index + 1);
                }
              }}
            >
              <Img fluid={node.childImageSharp.thumb} />
            </a>
          ))}
        </StackGrid>
      )}
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        customSources={customSources}
        sources={data[category].edges.map(
          ({ node }) => node.childImageSharp.large.src
        )}
      />
    </div>
  );
};

Image.propTypes = {
  category: PropTypes.oneOf([
    'bordados',
    'marcenaria',
    'midias',
    'mosaico',
    'pinturas',
    'videos'
  ])
};

export default Image;
